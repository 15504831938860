var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.$tr("setUpFacilityTitle"),
        description: _vm.$tr("setUpFacilityDescription"),
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KRadioButton", {
        staticClass: "radio-button",
        attrs: { label: _vm.$tr("joinFacilityLabel"), value: _vm.Options.JOIN },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("KRadioButton", {
        staticClass: "radio-button",
        attrs: {
          label: _vm.$tr("importFromFacilityLabel"),
          value: _vm.Options.IMPORT,
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm.showSelectAddressModal
        ? _c("SelectDeviceModalGroup", {
            on: {
              cancel: function ($event) {
                _vm.showSelectAddressModal = false
              },
              submit: _vm.handleContinueImport,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }