var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.$tr("importIndividualUsersHeader"),
        noBackAction: !_vm.noUsersImported,
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.steps,
        description: _vm.formDescription,
        submitText: _vm.coreString("importAction"),
        disabled:
          _vm.formSubmitted ||
          _vm.wizardService.state.context.importedUsers.length > 0,
        finishButton: _vm.users.length !== 0,
      },
      on: { continue: _vm.handleSubmit },
    },
    [
      _vm.noUsersImported
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.getCommonSyncString("warningFirstImportedIsSuperuser")
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      !_vm.facility
        ? _c("KCircularLoader")
        : _c(
            "div",
            [
              _c("p", { staticClass: "facility-name" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatNameAndId(_vm.facility.name, _vm.facility.id)
                    ) +
                    "\n    "
                ),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("enterCredentials")))]),
              _vm.error && !_vm.useAdmin
                ? _c("p", { style: { color: _vm.$themeTokens.error } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.coreString("invalidCredentialsError")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _c("KTextbox", {
                ref: "usernameTextbox",
                attrs: {
                  disabled: _vm.formSubmitted,
                  label: _vm.coreString("usernameLabel"),
                  autofocus: _vm.$attrs.autofocus,
                },
                on: {
                  blur: function ($event) {
                    _vm.blurred = true
                  },
                },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "username",
                },
              }),
              !_vm.facility.learner_can_login_with_no_password
                ? _c("PasswordTextbox", {
                    ref: "passwordTextbox",
                    attrs: {
                      disabled: _vm.formSubmitted,
                      value: _vm.password,
                      showConfirmationInput: false,
                      autocomplete: "new-password",
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.password = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "p",
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tr("doNotHaveUserCredentials")) +
                      "\n      "
                  ),
                  _c("KButton", {
                    attrs: {
                      text: _vm.profileString("useAdminAccount"),
                      appearance: "basic-link",
                    },
                    on: { click: _vm.openAdminCredentialsForm },
                  }),
                ],
                1
              ),
              _vm.deviceLimitations
                ? _c(
                    "KModal",
                    {
                      attrs: {
                        title: _vm.$tr("deviceLimitationsTitle"),
                        cancelText: _vm.coreString("cancelAction"),
                        submitText: _vm.coreString("importAction"),
                      },
                      on: { cancel: _vm.closeModal, submit: _vm.importUser },
                    },
                    [_c("p", [_vm._v(" " + _vm._s(_vm.modalMessage) + " ")])]
                  )
                : _vm._e(),
              _vm.useAdmin
                ? _c(
                    "KModal",
                    {
                      attrs: {
                        title: _vm.profileString("useAdminAccount"),
                        cancelText: _vm.coreString("cancelAction"),
                        submitText: _vm.coreString("continueAction"),
                      },
                      on: { cancel: _vm.closeModal, submit: _vm.moveAdmin },
                    },
                    [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.adminModalMessage) + " "),
                      ]),
                      _vm.error && _vm.useAdmin
                        ? _c(
                            "p",
                            { style: { color: _vm.$themeTokens.error } },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.coreString("invalidCredentialsError")
                                  ) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("KTextbox", {
                        ref: "adminUsernameTextbox",
                        attrs: {
                          label: _vm.coreString("usernameLabel"),
                          autofocus: _vm.$attrs.autofocus,
                        },
                        on: {
                          blur: function ($event) {
                            _vm.blurred = true
                          },
                        },
                        model: {
                          value: _vm.adminUsername,
                          callback: function ($$v) {
                            _vm.adminUsername =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "adminUsername",
                        },
                      }),
                      _c("PasswordTextbox", {
                        ref: "adminPasswordTextbox",
                        attrs: {
                          value: _vm.adminPassword,
                          showConfirmationInput: false,
                          autocomplete: "new-password",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.adminPassword = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }