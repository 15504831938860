var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.$tr("learningEnvironmentHeader"),
        footerMessageType: _vm.footerMessageType,
        step: 1,
        steps: 5,
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KRadioButton", {
        ref: "first-button",
        staticClass: "permission-preset-radio-button",
        attrs: {
          value: _vm.Presets.NONFORMAL,
          label: _vm.$tr("nonFormalLabel"),
          description: _vm.$tr("nonFormalDescription"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm.selected === _vm.Presets.NONFORMAL
        ? _c("FacilityNameTextbox", {
            ref: "facility-name",
            staticClass: "textbox",
            model: {
              value: _vm.facilityName,
              callback: function ($$v) {
                _vm.facilityName = $$v
              },
              expression: "facilityName",
            },
          })
        : _vm._e(),
      _c("KRadioButton", {
        staticClass: "permission-preset-radio-button",
        attrs: {
          value: _vm.Presets.FORMAL,
          label: _vm.$tr("formalLabel"),
          description: _vm.$tr("formalDescription"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm.selected === _vm.Presets.FORMAL
        ? _c("FacilityNameTextbox", {
            ref: "facility-name",
            staticClass: "textbox",
            model: {
              value: _vm.facilityName,
              callback: function ($$v) {
                _vm.facilityName = $$v
              },
              expression: "facilityName",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }