var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.getCommonSyncString("importFacilityAction"),
        eventOnGoBack: _vm.backEvent,
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.steps,
      },
      on: { continue: _vm.handleCredentialsSubmit },
    },
    [
      _c("FacilityAdminCredentialsForm", {
        ref: "credentialsForm",
        attrs: {
          disabled: _vm.formDisabled,
          device: _vm.device,
          facility: _vm.facility,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }