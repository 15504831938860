var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.coreString("deviceNameLabel"),
        description: _vm.$tr("deviceNameDescription"),
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KTextbox", {
        ref: "textbox",
        attrs: {
          label: _vm.coreString("deviceNameLabel"),
          autofocus: true,
          maxlength: 50,
          invalid: Boolean(_vm.invalidText),
          invalidText: _vm.invalidText,
        },
        on: {
          blur: function ($event) {
            _vm.shouldValidate = true
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }