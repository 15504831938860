var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "UserCredentialsForm",
        {
          attrs: {
            footerMessageType: _vm.footerMessageType,
            step: _vm.step,
            steps: _vm.steps,
            header: _vm.$tr("header"),
            description: _vm.getCommonSyncString(
              "superAdminPermissionsDescription"
            ),
            uniqueUsernameValidator: _vm.uniqueUsernameValidator,
            selectedUser: _vm.selectedImportedUser,
            noBackAction: true,
          },
          on: { submit: _vm.handleClickNext },
          scopedSlots: _vm._u(
            [
              {
                key: "aboveform",
                fn: function () {
                  return [
                    _vm.error
                      ? _c("p", { style: { color: _vm.$themeTokens.error } }, [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                _vm.coreString("invalidCredentialsError")
                              ) +
                              "\n    "
                          ),
                        ])
                      : _vm._e(),
                    _c("p", [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$tr("chooseAdminPrompt", {
                              facility: _vm.facility.name,
                            })
                          ) +
                          "\n    "
                      ),
                    ]),
                    _c("KSelect", {
                      staticClass: "select",
                      attrs: {
                        label: _vm.coreString("superAdminLabel"),
                        options: _vm.dropdownOptions,
                      },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    }),
                    !_vm.importedUserIsSelected
                      ? _c("p", [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                _vm.$tr("accountFacilityExplanation", {
                                  facility: _vm.facility.name,
                                })
                              ) +
                              "\n    "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3704356629
          ),
        },
        [
          _vm.importedUserIsSelected
            ? _c("p", [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm.$tr("enterPasswordPrompt", {
                        username: _vm.selected.label,
                        facility_name: _vm.facility.name,
                      })
                    ) +
                    "\n  "
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }