var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SubmissionStatePage",
    { attrs: { header: _vm.$tr("errorPageHeader") } },
    [
      _c("p", { staticClass: "error-page-subheader" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("errorPageSubheader")) + "\n  "),
      ]),
      _c("KButton", {
        staticClass: "error-page-retry-button",
        attrs: { text: _vm.$tr("errorPageRetryButtonLabel"), primary: true },
        on: { click: _vm.refreshPage },
      }),
      _c(
        "p",
        {
          staticClass: "error-page-subtext",
          style: { color: _vm.$themeTokens.annotation },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$tr("errorPageAdditionalGuidance")) + "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }