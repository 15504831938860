var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-page" },
    [
      _vm.coreError
        ? _c(
            "UiAlert",
            {
              staticClass: "alert",
              attrs: { dismissible: false, type: "error" },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.coreError))]),
              _c("br"),
              _vm.restart
                ? _c("KButton", {
                    attrs: {
                      appearance: "basic-link",
                      text: _vm.coreString("startOverAction"),
                    },
                    on: { click: _vm.startOver },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "main",
        { staticClass: "content" },
        [
          _c("KolibriLoadingSnippet"),
          _c("h1", { staticClass: "page-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$tr("pageTitle")) + "\n    "),
          ]),
          _c("p", { staticClass: "message" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$tr("pleaseWaitMessage")) + "\n    "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }