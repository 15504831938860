var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: { title: _vm.$tr("whatKindOfDeviceTitle") },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KRadioButton", {
        attrs: {
          label: _vm.$tr("fullDeviceLabel"),
          value: _vm.Options.FULL,
          description: _vm.$tr("fullDeviceDescription"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("KRadioButton", {
        attrs: {
          label: _vm.$tr("learnOnlyDeviceLabel"),
          value: _vm.Options.LOD,
          description: _vm.$tr("learnOnlyDeviceDescription"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }