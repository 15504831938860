var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: { title: _vm.$tr("languageFormHeader") },
      on: { continue: _vm.handleSubmit },
    },
    [_c("LanguageSwitcherList")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }