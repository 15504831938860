var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.$tr("header"),
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.step,
        noBackAction: true,
        description: _vm.$tr("description"),
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KButton", {
        ref: "modalButton",
        attrs: {
          "data-test": "modal-open-button",
          text: _vm.coreString("usageAndPrivacyLabel"),
          appearance: "basic-link",
        },
        on: {
          click: function ($event) {
            _vm.showModal = true
          },
        },
      }),
      _vm.showModal
        ? _c("PrivacyInfoModal", {
            attrs: { hideUsersSection: "" },
            on: { cancel: _vm.closeModal, submit: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }