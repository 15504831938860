var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserCredentialsForm", {
    attrs: {
      header: _vm.$tr("header"),
      description: _vm.getCommonSyncString("superAdminPermissionDescription"),
      footerMessageType: _vm.footerMessageType,
      disabled: _vm.loading,
      step: 1,
      steps: 2,
      doNotContinue: true,
      adminUserLabels: false,
      noBackAction: false,
      errors: _vm.caughtErrors,
    },
    on: {
      "update:errors": function ($event) {
        _vm.caughtErrors = $event
      },
      submit: _vm.handleClickNext,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }