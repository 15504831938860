var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onboarding" },
    [
      _vm.error
        ? _c("ErrorPage", {
            staticClass: "body",
            class: !_vm.windowIsLarge ? "mobile" : "",
          })
        : _vm.loading
        ? _c("LoadingPage", {
            staticClass: "body",
            class: !_vm.windowIsLarge ? "mobile" : "",
          })
        : [_c("router-view")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }