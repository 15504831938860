var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("KTextbox", {
        ref: "facilityName",
        attrs: {
          invalid: _vm.facilityNameIsInvalid,
          invalidText: _vm.facilityNameErrorMessage,
          label: _vm.$tr("facilityNameFieldLabel"),
          maxlength: 50,
        },
        on: {
          blur: _vm.validateFacilityName,
          input: function ($event) {
            return _vm.$emit("input", _vm.facilityName)
          },
        },
        model: {
          value: _vm.facilityName,
          callback: function ($$v) {
            _vm.facilityName = $$v
          },
          expression: "facilityName",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }