var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SubmissionStatePage",
    { attrs: { header: _vm.$tr("loadingPageHeader") } },
    [_vm._v("\n\n  " + _vm._s(_vm.$tr("loadingPageSubheader")) + "\n\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }