var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "base-container": true, windowIsSmall: _vm.windowIsSmall },
      style: {
        "background-color": _vm.windowIsSmall ? _vm.$themeTokens.surface : "",
      },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleEnterKey.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "logo-lang-container" },
        [
          !_vm.windowIsSmall
            ? _c(
                "div",
                { staticClass: "logo-wrapper" },
                [
                  _c("CoreLogo", { staticClass: "logo-image" }),
                  _c("span", { staticClass: "logo-text" }, [
                    _vm._v(_vm._s(_vm.coreString("kolibriLabel"))),
                  ]),
                ],
                1
              )
            : !_vm.noBackAction
            ? _c("KIconButton", {
                staticClass: "back-icon-button",
                attrs: { icon: "back" },
                on: {
                  click: function ($event) {
                    return _vm.wizardService.send(_vm.eventOnGoBack)
                  },
                },
              })
            : _vm._e(),
          _c("KButton", {
            staticClass: "languages-button",
            attrs: {
              icon: "language",
              text: _vm.selectedLanguage.lang_name,
              primary: false,
              appearance: "flat-button",
            },
            on: {
              click: function ($event) {
                _vm.showLanguageModal = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        _vm.windowIsSmall ? "div" : "KPageContainer",
        { tag: "component", attrs: { topMargin: 16, noPadding: true } },
        [
          _vm.coreError
            ? _c(
                "div",
                { staticStyle: { padding: "16px" } },
                [
                  _c(
                    "AppError",
                    {
                      attrs: { hideParagraphs: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "buttons",
                            fn: function () {
                              return [
                                _c("KButton", {
                                  attrs: {
                                    text: _vm.coreString("startOverAction"),
                                    primary: true,
                                  },
                                  on: { click: _vm.startOver },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3207282553
                      ),
                    },
                    [_c("h2", [_vm._v(_vm._s(_vm.coreError))])]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.showBackArrow
                    ? _c("KIconButton", {
                        staticStyle: { "margin-left": "-12px" },
                        attrs: { icon: "back" },
                        on: {
                          click: function ($event) {
                            return _vm.wizardService.send(_vm.eventOnGoBack)
                          },
                        },
                      })
                    : _vm._e(),
                  _c("h1", { staticClass: "title" }, [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                  ]),
                  _vm.description
                    ? _c("p", { staticClass: "description" }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.description) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._t("default"),
                ],
                2
              ),
          !_vm.coreError
            ? _c(
                "div",
                {
                  staticClass: "footer",
                  style: {
                    borderTop:
                      "1px solid " +
                      (_vm.windowIsSmall
                        ? _vm.$themeTokens.surface
                        : _vm.$themeTokens.fineLine),
                  },
                },
                [
                  !_vm.windowIsSmall
                    ? _c(
                        "div",
                        { staticClass: "footer-section" },
                        [
                          _vm._t("footer"),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.footerMessage) +
                              "\n      "
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.windowIsSmall
                    ? _c(
                        "KButtonGroup",
                        { staticClass: "footer-actions footer-section" },
                        [
                          _vm._t("buttons"),
                          !_vm.noBackAction && !_vm.$slots.buttons
                            ? _c("KButton", {
                                attrs: {
                                  text: _vm.coreString("goBackAction"),
                                  appearance: "flat-button",
                                  primary: false,
                                  disabled: _vm.navDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.wizardService.send(
                                      _vm.eventOnGoBack
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          !_vm.$slots.buttons
                            ? _c("KButton", {
                                attrs: {
                                  text: _vm.coreString("continueAction"),
                                  primary: true,
                                  disabled: _vm.navDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("continue")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.windowIsSmall
                    ? _c(
                        "div",
                        { staticClass: "mobile-footer" },
                        [
                          _vm._t("buttons"),
                          !_vm.$slots.buttons
                            ? _c("KButton", {
                                staticClass: "mobile-continue-button",
                                attrs: {
                                  text: _vm.coreString("continueAction"),
                                  primary: true,
                                  disabled: _vm.navDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("continue")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.showLanguageModal
        ? _c("LanguageSwitcherModal", {
            staticClass: "ta-l",
            on: {
              cancel: function ($event) {
                _vm.showLanguageModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }