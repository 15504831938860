var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "submission-state-page" },
    [
      _c("KCircularLoader", {
        staticClass: "submission-state-page-spinner",
        attrs: { delay: false },
      }),
      _c(
        "h1",
        {
          staticClass: "submission-state-page-header",
          style: { color: _vm.$themeTokens.primary },
        },
        [_vm._v("\n    " + _vm._s(_vm.header) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "submission-state-page-body" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }