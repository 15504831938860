var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.header,
        navDisabled: _vm.runningTasks.length > 0,
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.steps,
      },
      on: {
        continue: function ($event) {
          return _vm.$emit("click_next")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function () {
            return [
              _vm.loadingTask.status === "COMPLETED"
                ? _c("KButton", {
                    attrs: { primary: "", text: _vm.nextButtonLabel },
                    on: { click: _vm.handleClickContinue },
                  })
                : _vm.loadingTask.status === "FAILED"
                ? [
                    _c("KButton", {
                      attrs: {
                        text: _vm.coreString("startOverAction"),
                        appearance: "flat-button",
                      },
                      on: { click: _vm.startOver },
                    }),
                    _c("KButton", {
                      attrs: {
                        primary: "",
                        text: _vm.coreString("retryAction"),
                      },
                      on: { click: _vm.retryImport },
                    }),
                  ]
                : _c("span"),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.loadingTask.status
        ? _c("FacilityTaskPanel", {
            attrs: { task: _vm.loadingTask },
            on: { cancel: _vm.cancelTask },
          })
        : _c("KCircularLoader"),
      _vm.loadingTask.status === "COMPLETED" && _vm.isImportingSoud
        ? _c("KButton", {
            attrs: { appearance: "basic-link", text: _vm.$tr("importAnother") },
            on: { click: _vm.importAnother },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }