var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.steps,
        showBackArrow: true,
        eventOnGoBack: _vm.backArrowEvent,
        title: _vm.$tr("selectAUser"),
        description: _vm.facilityDescription,
      },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function () {
            return [_c("div")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "device-name" }, [
        _vm._v("\n    " + _vm._s(_vm.deviceDescription) + "\n  "),
      ]),
      _vm.noUsersImported
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.getCommonSyncString("warningFirstImportedIsSuperuser")
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c("PaginatedListContainer", {
        attrs: {
          items: _vm.learners,
          filterPlaceholder: _vm.coreString("searchForUser"),
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var items = ref.items
              return [
                _c("UserTable", {
                  attrs: {
                    users: items,
                    selectable: false,
                    showDemographicInfo: false,
                    value: _vm.usersID,
                    selectedStyle: "color:" + _vm.$themeTokens.textDisabled,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function (userRow) {
                          return [
                            !_vm.isImported(userRow.user) &&
                            !_vm.isImporting(userRow.user)
                              ? _c("KButton", {
                                  attrs: {
                                    text: _vm.coreString("importAction"),
                                    appearance: "flat-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startImport(userRow.user)
                                    },
                                  },
                                })
                              : _vm.isImporting(userRow.user)
                              ? _c("KCircularLoader", {
                                  staticStyle: { margin: "4px auto 0" },
                                  attrs: { size: 24 },
                                })
                              : _c("p", { staticClass: "imported" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$tr("imported")) +
                                      "\n          "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }