var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.loading ? "" : _vm.header,
        footerMessageType: _vm.loading ? null : _vm.footerMessageType,
        step: _vm.loading ? null : 1,
        steps: _vm.loading ? null : 5,
      },
      on: { continue: _vm.handleContinue },
    },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              !_vm.loadingNewAddress
                ? _c("RadioButtonGroup", {
                    staticClass: "radio-group",
                    attrs: {
                      items: _vm.facilities,
                      currentValue: _vm.selectedFacilityId,
                      itemLabel: function (x) {
                        return _vm.formatNameAndId(x.name, x.id)
                      },
                      itemValue: function (x) {
                        return x.id
                      },
                      disabled: _vm.formDisabled,
                    },
                    on: {
                      "update:currentValue": function ($event) {
                        _vm.selectedFacilityId = $event
                      },
                      "update:current-value": function ($event) {
                        _vm.selectedFacilityId = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "label",
                {
                  staticClass: "select-button-label",
                  attrs: { for: "select-address-button" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tr("selectDifferentDeviceLabel")) +
                      "\n    "
                  ),
                ]
              ),
              _c("KButton", {
                attrs: {
                  id: "select-address-button",
                  appearance: "basic-link",
                  text: _vm.getCommonSyncString("addNewAddressAction"),
                },
                on: {
                  click: function ($event) {
                    _vm.showSelectAddressModal = true
                  },
                },
              }),
              _vm.showSelectAddressModal
                ? _c("SelectDeviceModalGroup", {
                    on: {
                      cancel: function ($event) {
                        _vm.showSelectAddressModal = false
                      },
                      submit: _vm.handleAddressSubmit,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("KCircularLoader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }