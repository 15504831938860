var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        dir: "auto",
        noBackAction: true,
        title: _vm.getCommonSyncString("howAreYouUsingKolibri"),
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KRadioButton", {
        staticStyle: { "margin-bottom": "1em" },
        attrs: {
          value: _vm.UsePresets.ON_MY_OWN,
          label: _vm.$tr("onMyOwnLabel"),
          description: _vm.getCommonSyncString("onMyOwn"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("KRadioButton", {
        attrs: {
          value: _vm.UsePresets.GROUP,
          label: _vm.$tr("groupLearningLabel"),
          description: _vm.$tr("groupLearningDescription"),
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }