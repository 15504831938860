var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        title: _vm.$tr("header"),
        footerMessageType: _vm.footerMessageType,
        step: 3,
        steps: 5,
        eventOnGoBack: _vm.backEvent,
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _c("KRadioButton", {
        ref: "yesRadio",
        staticClass: "radio-button",
        attrs: { label: _vm.$tr("yesOptionLabel"), value: true },
        model: {
          value: _vm.setting,
          callback: function ($$v) {
            _vm.setting = $$v
          },
          expression: "setting",
        },
      }),
      _c("KRadioButton", {
        ref: "noRadio",
        staticClass: "radio-button",
        attrs: { label: _vm.$tr("noOptionLabel"), value: false },
        model: {
          value: _vm.setting,
          callback: function ($$v) {
            _vm.setting = $$v
          },
          expression: "setting",
        },
      }),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.getCommonSyncString("changeLater")) + "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }