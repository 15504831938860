var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingStepBase",
    {
      attrs: {
        dir: "auto",
        title: _vm.header,
        footerMessageType: _vm.footerMessageType,
        step: _vm.step,
        steps: _vm.steps,
        description: _vm.description,
        noBackAction: _vm.noBackAction,
      },
      on: { continue: _vm.handleContinue },
    },
    [
      _vm._t("aboveform"),
      _vm._t("form", function () {
        return [
          _c("FullNameTextbox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.selectedUser,
                expression: "!selectedUser",
              },
            ],
            ref: "fullNameTextbox",
            attrs: {
              value: _vm.fullName,
              isValid: _vm.fullNameValid,
              shouldValidate: _vm.formSubmitted,
              disabled: _vm.disabled,
              autofocus: true,
              autocomplete: "name",
            },
            on: {
              "update:value": function ($event) {
                _vm.fullName = $event
              },
              "update:isValid": function ($event) {
                _vm.fullNameValid = $event
              },
              "update:is-valid": function ($event) {
                _vm.fullNameValid = $event
              },
            },
          }),
          _c("UsernameTextbox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.selectedUser,
                expression: "!selectedUser",
              },
            ],
            ref: "usernameTextbox",
            attrs: {
              value: _vm.username,
              isValid: _vm.usernameValid,
              disabled: _vm.disabled,
              shouldValidate: _vm.formSubmitted,
              errors: _vm.caughtErrors,
              isUniqueValidator: !_vm.selectedUser
                ? _vm.uniqueUsernameValidator
                : function () {
                    return true
                  },
            },
            on: {
              "update:value": function ($event) {
                _vm.username = $event
              },
              "update:isValid": function ($event) {
                _vm.usernameValid = $event
              },
              "update:is-valid": function ($event) {
                _vm.usernameValid = $event
              },
              "update:errors": function ($event) {
                _vm.caughtErrors = $event
              },
            },
          }),
          _c("PasswordTextbox", {
            ref: "passwordTextbox",
            attrs: {
              value: _vm.password,
              disabled: _vm.disabled,
              isValid: _vm.passwordValid,
              shouldValidate: _vm.formSubmitted,
              showConfirmationInput: !_vm.selectedUser,
              autocomplete: "new-password",
            },
            on: {
              "update:value": function ($event) {
                _vm.password = $event
              },
              "update:isValid": function ($event) {
                _vm.passwordValid = $event
              },
              "update:is-valid": function ($event) {
                _vm.passwordValid = $event
              },
            },
          }),
          !_vm.hidePrivacyLink ? _c("PrivacyLinkAndModal") : _vm._e(),
        ]
      }),
      _vm._t("footer", function () {
        return [
          _c("div", { staticClass: "reminder" }, [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("KIcon", { attrs: { icon: "warning" } })],
              1
            ),
            _c("p", { staticClass: "text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.coreString("rememberThisAccountInformation")) +
                  "\n      "
              ),
            ]),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }